
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { Form as AntForm } from 'ant-design-vue';

import {
  getFeedback,
  putFeedback,
  FeedbackData,
  initFeedbackData,
  getFeedbackList,
} from '@/api/operation/feedback';
import { PaginationData } from '@/api/operation/customer';
import moment, { Moment } from 'moment';
import MultipleImageUpload from '@/component/multiple-image-upload.vue';
import { useMain } from '@/pinia/index';
import ReplyModel from './components/ReplyModel.vue';
import ModalItem from '@/component/ModalItem/ModalItem.vue';
// 搜索条件
interface Form {
  message: string;
  message_type: number;
  manage_status: number;
  time: Moment[];
  updata_time: Moment[];
}
// 弹窗表单数据
interface ReplyData {
  user_name: string;
  category_id: number;
  user_mobile: string;
  content: string;
  echo: string;
  type: boolean;
  id: number;
  url_list: any;
}
function initForm(): Form {
  return {
    message: '',
    message_type: 0,
    manage_status: 0,
    time: [],
    updata_time: [],
  };
}
// 处理状态
const MANAGE_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 2,
    value: '已处理',
  },
  {
    id: 1,
    value: '未处理',
  },
];
@Component({
  name: 'Feedback',
  components: {
    MultipleImageUpload,
    ReplyModel,
    ModalItem,
  },
})
export default class Feedback extends Vue {
  //搜索数据
  form_filter_data: Form = initForm();
  //问题分类选项
  feedbackList: any[] = [];
  form_item_layout: any = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  form_edit_layout: any = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  is_loading: boolean = false;
  is_show_modal: boolean = false;
  //table数据
  message_data: PaginationData<FeedbackData> = initFeedbackData();
  //回复弹窗信息
  replyData: ReplyData = {
    user_name: '',
    category_id: 1,
    user_mobile: '',
    content: '',
    echo: '',
    type: true,
    id: 0,
    url_list: [],
  };
  
  preview_image: string = '';
  preview_visible: boolean = false;
  searchForm: any;
  pagination = useMain().pagination;
  replyModelVisible = false;

  get list() {
    console.log(this.feedbackList.slice(1));

    return this.feedbackList.slice(1);
  }
  get manageStatus() {
    return MANAGE_STATUS;
  }
  log(a) {
    return console.log(a);
  }
  async submit(e) {
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        this.form_filter_data = {
          ...values,
        };
        this.message_data.current = 1;
        this.searchMessage();
      }
    });
  }
  // sorterId(a: any, b: any) {
  //   return b.id - a.id;
  // }
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  //获取问题分类
  getFeedbackType(id: number) {
    let text = '';
    this.feedbackList.forEach(item => {
      if (item.id === id) text = item.name;
    });
    return text;
  }
  handlePreviewCancel() {
    this.preview_visible = false;
  }
  //   操作事件
  handleReply(data: any, type: boolean) {
    const { user_name, category_id, user_mobile, content, echo, id, image } = data;
    this.replyData = {
      user_name,
      user_mobile,
      content,
      echo,
      type,
      id,
      category_id: category_id ? category_id : 1,
      url_list: image === '' ? [] : image.split(','),
    };
    this.is_show_modal = true;
  }
  //   富文本
  openReolyModal(record) {
    this.replyModelVisible = true;
    this.replyData = record;
  }
  //   回复
  async reply(echo, coupon_id) {
    const res = await putFeedback(
      { coupon_id, echo, category_id: this.replyData.category_id },
      this.replyData.id
    );
    if (res.status === 200) {
      await this.searchMessage();
      this.$message.success('处理成功');
      this.replyModelVisible = false;
      this.is_show_modal = false;
    } else {
      this.$message.error((res as any).message);
    }
  }
  paginationOption(data: any) {
    return {
      current: data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  //   获取时间
  getTime(data: any) {
    return moment(data * 1000).format('YYYY-MM-DD HH:mm:ss');
  }
  // 分页
  //   @changeLoading(['is_loading'])
  async handleChange(data: any, filter: any, sort: any) {
    this.message_data.current = data.current;
    this.searchMessage();
  }
  //   处理时间范围
  getSectionTime(time: Moment, type: number) {
    switch (type) {
      case 1:
        return time.startOf('day').unix();
      case 2:
        return time.endOf('day').unix();
      default:
        return;
    }
  }
  //   请求参数
  getReqData() {
    const start_time = this.form_filter_data.time[0]
      ? this.getSectionTime(this.form_filter_data.time[0], 1)
      : '';
    const end_time = this.form_filter_data.time[1]
      ? this.getSectionTime(this.form_filter_data.time[1], 1)
      : '';
    const echo_start_time = this.form_filter_data.updata_time[0]
      ? this.getSectionTime(this.form_filter_data.updata_time[0], 1)
      : '';
    const echo_end_time = this.form_filter_data.updata_time[1]
      ? this.getSectionTime(this.form_filter_data.updata_time[1], 1)
      : '';
    return {
      search: this.form_filter_data.message,
      category_id:
        this.form_filter_data.message_type === 0 ? '' : this.form_filter_data.message_type,
      status: this.form_filter_data.manage_status === 0 ? '' : this.form_filter_data.manage_status,
      start_time,
      end_time,
      echo_start_time,
      echo_end_time,
      current: this.message_data.current,
    };
  }

  // 搜索消息
  @changeLoading(['is_loading'])
  async searchMessage() {
    const query_params = this.getReqData();
    await this.getMessageList(query_params);
  }
  // 清空搜索条件
  clearForm() {
    this.form_filter_data = initForm();
    this.searchForm.resetFields();
    this.message_data.current = 1;
    this.searchMessage();
  }
  // 获取意见列表
  @changeLoading(['is_loading'])
  async getMessageList(query_params: any = {}) {
    const res = await getFeedback(query_params);
    if (res.data && !Array.isArray(res.data)) {
      this.message_data = initFeedbackData(res.data);
    }
  }
  @changeLoading(['is_loading'])
  async created() {
    this.searchForm = (AntForm as any)?.createForm(this, {
      name: 'form',
    });
    if (this.pagination?.feedback) {
      Object.keys(this.pagination.feedback).forEach(item => {
        if (item !== 'current') {
          this.form_filter_data[item] = this.pagination.feedback[item];
        } else {
          this.message_data.current = this.pagination.feedback[item];
        }
      });
      this.searchMessage();
      delete this.pagination.feedback;
      return;
    }
    const res = await getFeedbackList();
    this.feedbackList = [{ id: 0, name: '全部' }, ...res.data];
    await this.getMessageList();
  }
  onManage() {
    this.pagination.feedback = {
      ...this.searchForm,
      current: this.message_data.current,
    };
  }
}
