import { render, staticRenderFns } from "./feedback.vue?vue&type=template&id=8511b502&scoped=true"
import script from "./feedback.vue?vue&type=script&lang=ts"
export * from "./feedback.vue?vue&type=script&lang=ts"
import style0 from "./feedback.vue?vue&type=style&index=0&id=8511b502&prod&lang=less&scoped=true"
import style1 from "./feedback.vue?vue&type=style&index=1&id=8511b502&prod&lang=stylus&module=true"
import style2 from "./feedback.vue?vue&type=style&index=2&id=8511b502&prod&lang=stylus&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8511b502",
  null
  
)

export default component.exports