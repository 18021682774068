import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
const getUrl = require('baimahu/url-with-param');
export interface FeedbackData {
    id: number;
    status: number;
    user_id: number;
    user_name: string;
    user_mobile: string;
    category_id: number;
    content: string;
    echo: string;
    create_time: number;
    update_time: number;
}
export function initFeedbackData(remote?: PaginationData<FeedbackData>): PaginationData<FeedbackData> {
    remote = remote || {} as PaginationData<FeedbackData>;
    return {
        current: remote.current || 0,
        detail: remote.detail || [],
        limit: remote.limit || 0,
        total: remote.total || 0,
        last: remote.last || 0,
    }
}
export async function getFeedback(param: any) {
    if(!param.current){
        param.current = 1
    }
    const data = getUrl('/boss/operation/feedback',param)
    return await axios.get(data);
}
export async function getFeedbackList() {
    return await axios.get('/boss/operation/feedback/category');
}
export async function putFeedback(param: any,id: number) {
    return await axios.put(`/boss/operation/feedback/${id}`,param);
}
